<!--
 * @Author: flf flf@youlove.com.cn
 * @Date: 2022-11-08 15:52:02
 * @LastEditors: flf flf@youlove.com.cn
 * @LastEditTime: 2024-06-17 16:43:57
 * @FilePath: \wap\src\views\zzy\zyzz.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div>
    </div>
</template>
<script>
export default {
    created() {
        this.$globalFun.setUrlHeader('/pages/home/domicile/index');
    }
}
</script>